import React from "react";
import ContentLoader from "react-content-loader";

const MyLoader = (props) => (
    <ContentLoader
      speed={2}
      width={400}
      height={160}
      viewBox="0 0 400 160"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="48" y="8" width="88" height="6" rx="3" />
      <rect x="48" y="26" width="52" height="6" rx="3" />
      <rect x="0" y="56" width="410" height="6" rx="3" />
      <rect x="0" y="72" width="380" height="6" rx="3" />
      <rect x="0" y="88" width="178" height="6" rx="3" />
      <circle cx="20" cy="20" r="20" />
    </ContentLoader>
  );

  export default MyLoader;